<template>
  <section
    id="condizioni-contrattuali"
    :class="isFormUtente ? 'non-bianco' : null"
  >
    <p
      :id="isFormUtente ? null : 'accetta-condizioni'"
      v-html="msgGenerale"
    ></p>
    <label for="contrattuali"
      >{{ msgCheck.contrattuali }}<sup>*</sup
      ><input type="checkbox" name="contrattuali"
    /></label>
    <p id="accetto-condizioni">{{ msgAccetto }}</p>
    <label for="vessatorie"
      >{{ msgCheck.vessatorie }}<sup>*</sup
      ><input type="checkbox" name="vessatorie"
    /></label>
    <label for="privacy"
      >{{ msgCheck.privacy }}<sup>*</sup><input type="checkbox" name="privacy"
    /></label>
  </section>
</template>

<script>
const NAME = "CondizioniContrattualiSpSa";
const URL_CONTRATTO = "http://s.mida4.it/ContrattoSpeseSanitarie";

export default {
  name: NAME,
  props: ["isFormUtente"],
  data() {
    return {
      msgGenerale: this.isFormUtente
        ? `Prima di continuare scarica, leggi e salva il <a href="${URL_CONTRATTO}" target="_blank">nostro contratto</a>. Per procedere sarà necessario accettare le condizioni lì riportate.`
        : `Prima di continuare assicurati di aver scaricato, letto e salvato il <a href="${URL_CONTRATTO}" target="_blank">nostro contratto</a>. Per procedere sarà necessario accettare le condizioni lì riportate.`,
      msgAccetto:
        "Ai sensi e per gli effetti degli artt. 1341 e 1342 c.c., il Cliente approva espressamente i seguenti articoli: 2 (Durata del Servizio Online), 3 (Assistenza), 4 (Utilizzo del Servizio), 6 (Disponibilità del Servizio), 8 (Backup degli Archivi), 9 (Limitazioni Responsabilità), 13 (Corrispettivo), 14 (Sospensione Servizio), 15 (Durata del Contratto), 16 (Diritto di Modifica) e 17 (Foro Competente).",
      msgCheck: {
        contrattuali: "Accetto le condizioni contrattuali",
        vessatorie: "Accetto le clausole vessatorie",
        privacy: "Accetto l'informativa privacy",
      },
    };
  },
};
</script>