<template>
  <section id="dati-utente" :class="isRegistrazione ? null : 'modifica-dati'">
    <section id="benvenuto" v-if="isRegistrazione">
      <p>
        Compila questo form accettando le condizioni indicate in calce per
        iniziare la prova gratuita di 15 giorni.
      </p>
      <p>
        Tramite il nostro sistema potrai inviare automaticamente le parcelle al
        Sistema Tessera Sanitaria ed ai tuoi clienti.
      </p>
    </section>
    <FormSchemaNative
      ref="formUtente"
      id="form-utente"
      :schema="SchemaUtente"
      v-model="utente"
      v-noac
      :key="this.parent"
    >
      <fieldset>
        <FormSchemaNative
          ref="formIndirizzo"
          id="form-indirizzo"
          :schema="SchemaIndirizzo"
          v-model="utente.sede"
        />
      </fieldset>
      <fieldset>
        <section id="info-default" v-if="isRegistrazione">
          <p>
            Inserisci i valori predefiniti per il documento. Nel dubbio puoi
            lasciare quelli già impostati.
          </p>
        </section>
        <FormSchemaNative
          ref="formDefaultDoc"
          id="form-default-documento"
          :schema="SchemaDefaultDoc"
          v-model="utente.defaultDocumento"
        />
      </fieldset>
    </FormSchemaNative>
    <div v-frag v-if="isRegistrazione">
      <CondizioniContrattualiSpSa :isFormUtente="true" />
      <VueRecaptcha
        id="recaptcha-container"
        ref="recaptcha"
        :sitekey="siteKey"
        :loadRecaptchaScript="true"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
      />
      <button
        v-show="recaptchaVerified === true"
        @click="salvaUtente"
        :id="`btn${parentClassName}`"
      >
        {{ submitLabel }}
      </button>
    </div>
    <button v-else @click="salvaUtente" :id="`btn${parentClassName}`">
      {{ submitLabel }}
    </button>
  </section>
</template>

<script>
  const NAME = "FormUtenteSpSa";

  import { DefaultsSpSa as DEFAULTS } from "@/entities/enums/defaultsSpSa";
  import { Forms, kebabCase, R, REGEXES } from "mida4-web-app-utils";
  import { ValoriIVA } from "mida4-fattura-rapida-spese-sanitarie";
  import { getUtenteDaSalvare } from "mida4-fattura-rapida-spese-sanitarie/doc";

  import FormSchemaNative from "@formschema/native";
  import StatusCodes from "http-status-codes";

  import { mapGetters } from "vuex";

  import VueRecaptcha from "vue-recaptcha";

  import SchemaUtente from "@/entities/schemas/utente.json";
  import SchemaIndirizzo from "@/entities/schemas/indirizzo.json";
  import SchemaDefaultDoc from "@/entities/schemas/default-documento.json";
  import Invianti from "@/entities/enums/invianti";
  import TipiSpesa from "@/entities/enums/tipi-spesa";

  import CondizioniContrattualiSpSa from "@/components/_common/CondizioniContrattualiSpSa";

  SchemaUtente.properties.tipoSoggetto.enum = Object.keys(Invianti)
    .filter((t) => t !== "isStruttura")
    .sort()
    .reverse();

  SchemaDefaultDoc.properties = {
    ...SchemaDefaultDoc.properties,
    IVA: {
      title: "IVA",
      type: "string",
      enum: ValoriIVA.getEnum(DEFAULTS.DESTINAZIONE),
    },
  };

  SchemaDefaultDoc.properties.tipoSpesa.enum = TipiSpesa.fromBitMask(
    TipiSpesa.ALL
  ).map(([val, lbl]) => creaOggettoTipoSpesa(val, lbl, null));
  function creaOggettoTipoSpesa(valore, descrizione, tipoSpesaDefault) {
    return {
      val: valore,
      lbl: `${valore} - ${descrizione}`,
      selected: tipoSpesaDefault === valore,
    };
  }

  // eslint-disable-next-line no-unused-vars
  const requiredFields = [
    ...SchemaUtente.required,
    ...SchemaIndirizzo.required,
    ...SchemaDefaultDoc.required,
  ];

  export default {
    name: NAME,
    props: ["parent", "edit"],
    components: {
      VueRecaptcha,
      FormSchemaNative,
      CondizioniContrattualiSpSa,
    },
    data() {
      return {
        NAME: NAME,
        SchemaUtente: SchemaUtente,
        SchemaIndirizzo: SchemaIndirizzo,
        SchemaDefaultDoc: SchemaDefaultDoc,
        recaptchaVerified: this.isRegistrazione === false,
        recaptchaToken: null,
        utente: {
          sede: {},
          defaultDocumento: {},
        },
        utenteDiff: null,
      };
    },
    computed: {
      ...mapGetters({
        userData: "user/getData",
        defaultDocData: "user/getDefaults",
      }),
      parentClassName() {
        return kebabCase(this.parent);
      },
      isRegistrazione() {
        return this.parent === "RegistrazioneSpSa";
      },
      utenteDaSalvare() {
        // debugger; // eslint-disable-line no-debugger
        // this.$log.debug('iva', this.utente.defaultDocumento.IVA)
        // this.$log.debug('iva', this.utente.defaultDocumento.IVA.toString())
        return getUtenteDaSalvare(this.utente);
      },
      siteKey() {
        return process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_TEST_RECAPTCHA_SITE_K
          : process.env.VUE_APP_RECAPTCHA_SITE_K;
      },
      submitLabel() {
        return this.isRegistrazione ? "Registrazione" : "Salva";
      },
    },
    created() {
      if (!this.isRegistrazione) {
        this.utente = R.clone(this.userData);
      }
      this.clearSchema();
    },
    mounted() {
      if (!this.isRegistrazione) {
        document.querySelector(`select[name^=IVA]`).value =
          this.defaultDocData.IVA;
        this.reverseOptions();
        this.impostaReadonly();
      } else {
        Forms.aggiungiNotaCampiObbligatori();
        document.querySelector(`select[name^=IVA]`).value = this.DEFAULTS.IVA;
        this.addExtraValidations();
      }
      this.setUserEditable(this.edit);

      Forms.riordinaFormUtente(this, this.DEFAULTS.DESTINAZIONE);
      Forms.aggiungiClasseCampiObbligatori(requiredFields);

      this.listenForm(this.$refs.formUtente);
      this.handleTipoSoggettoChange({ u: this.utente, resetTipoSpesa: false });
    },

    beforeDestroy() {
      document.querySelectorAll("input").forEach((i) => {
        [Forms.evTargetToUpper, this.copiaCodiceFiscaleSuCFTitolare].forEach(
          (fn) => i.removeEventListener("input", fn)
        );
      });
    },

    methods: {
      /**
       * per qualche motivo se sono sul form
       * di modifica dati utente le opzioni dei select appaiono
       * in ordine inverso - col metodo qui sotto le riordino
       */
      reverseOptions() {
        const _reverse = (sel) => {
          const selectEl = document.querySelector(sel);
          const options = Array.from(selectEl.querySelectorAll("option"));
          options.reverse();
          options
            .filter((o) => !o.value)
            .concat(options.filter((o) => !!o.value))
            .forEach((o) => selectEl.appendChild(o));
        };
        ["select[name=tipoCassaPrevidenziale]", "select[name=IVA]"].forEach(
          _reverse
        );
      },

      // solo STS
      handleTipoSoggettoChange({ u, resetTipoSpesa }) {
        const listInputEl = this.$refs.formDefaultDoc.$el.querySelector(
          "select[name=tipoSpesa]"
        );

        if (!(R.isNil(u.tipoSoggetto) || R.isEmpty(u.tipoSoggetto))) {
          listInputEl.innerHTML = null;
          TipiSpesa.fromBitMask(Invianti[u.tipoSoggetto])
            .map(([val, lbl]) =>
              Forms.getOptionEl(
                creaOggettoTipoSpesa(val, lbl, u.defaultDocumento.tipoSpesa)
              )
            )
            .forEach((el) => listInputEl.add(el));

          if (resetTipoSpesa) {
            listInputEl.options[0].selected = true;
            listInputEl.dispatchEvent(new Event("change"));
          }
        }
      },

      addExtraValidations() {
        Forms.convalidaCF(this);
      },
      onCaptchaVerified(recaptchaToken) {
        this.recaptchaVerified = true;
        this.recaptchaToken = recaptchaToken;
      },
      onCaptchaExpired() {
        this.recaptchaVerified = false;
        this.$refs.recaptcha.reset();
      },
      // necessario per visualizzare gli oggetti embedded come fieldset ...
      clearSchema() {
        delete SchemaDefaultDoc.properties.iva;
        delete SchemaUtente.properties.defaultDocumento;
        delete SchemaUtente.properties.indirizzo;
      },
      // parametri per registrazione o aggiornamento
      getParametriSalvataggio() {
        const pReg = {
          apiCall: "registraUtente",
          storeAction: "user/newUser",
          successTitle: "Registrazione completata",
          successMsg:
            "La registrazione è andata a buon fine, ti preghiamo di seguire le istruzioni inviate via mail per l'attivazione.",
          errorTitle: "Errore registrazione",
          errorMsg: "Il server ha restituito un errore: ",
        };
        const pAgg = {
          apiCall: "aggiornaDatiUtente",
          storeAction: "user/setUser",
          successTitle: "Dati aggiornati",
          successMsg: "I dati dell'utente sono stati correttamente salvati",
          errorTitle: "Errore aggiornamento dati utente",
          errorMsg: "Il server ha restituito un errore: ",
        };

        return this.parent == "RegistrazioneSpSa" ? pReg : pAgg;
      },
      // salvataggio
      async salvaUtente() {
        // Log.group('FormUtente.salvaUtente')
        const {
          apiCall,
          storeAction,
          successTitle,
          successMsg,
          errorTitle,
          errorMsg,
        } = this.getParametriSalvataggio();

        const condizioniContrattualiAccettate = () =>
          ["contrattuali", "vessatorie", "privacy"].every(
            (check) =>
              document.querySelector(`#condizioni-contrattuali [name=${check}]`)
                .checked
          );

        const isValid = () =>
          Forms.check(Object.values(this.$refs)) === null &&
          (!this.isRegistrazione || this.recaptchaVerified);

        // verifica accettazione condizioni contrattuali
        if (this.isRegistrazione && !condizioniContrattualiAccettate()) {
          this.$refs.formUtente.setErrorMessage(
            `Accettare le condizioni contrattuali per completare la registrazione`
          );
          window.scroll(0, 0);
          document
            .querySelectorAll("#condizioni-contrattuali [type=checkbox]")
            .forEach((el) =>
              el.addEventListener(
                "change",
                () => this.$refs.formUtente.clearErrorMessage(),
                { once: true }
              )
            );
          return;
        }
        // verifica campi obbligatori e recaptcha
        // if (this.$log.debug("isValid", isValid())) {
        if (isValid()) {
          this.toggleLoader(true);
          // this.$log.debug(
          //   "qui",
          //   JSON.stringify(this.getParametriSalvataggio(), null, 2)
          // );
          try {
            // Log.debug("controlli OK: salvo utente", this.utenteDaSalvare)
            let res = await this.$api[apiCall](
              this.utenteDaSalvare,
              this.recaptchaToken
            );

            if (res.status == StatusCodes.OK) {
              // Log.info("salvataggio OK")

              // bus.$emit('editUser', false)
              this.setUserEditable(false);

              // vuex salva utente in stato
              if (
                R.isEmpty(this.utente.defaultDocumento.tipoCassaPrevidenziale)
              ) {
                this.utente.defaultDocumento.tipoCassaPrevidenziale = null;
              }
              this.$store.dispatch(storeAction, this.utente);
              this.showSuccess({ title: successTitle, message: successMsg });
              this.$emit("registrazione");
            } else {
              const msg = (await res.json()).message;
              // Log.error("errore registrazione", msg)
              // notifica errore registrazione
              this.showError({ title: errorTitle, message: errorMsg + msg });
              if (this.isRegistrazione) {
                this.$refs.recaptcha.reset();
              }
            }
            this.toggleLoader(false);
            // Log.endGroup('FormUtente.salvaUtente')
          } catch (e) {
            this.toggleLoader(false);
            this.$log.error(apiCall, e);
            if (REGEXES.ERR_INTERNO_FORM_UTENTE.test(e.message)) {
              this.showError({
                title: "Errore interno",
                message: "Si è verificato un errore, contattare l'assistenza",
              });
              // Log.endGroup('FormLogin.login')
              return;
            }
            // Log.error("errore di rete durante la registrazione " +  e.message)
            // Log.endGroup('FormUtente.salvaUtente')
            this.showError({
              title: "Errore di rete",
              message:
                "Non è stato possibile contattare il server. Controllare la connessione",
            });
          }
        } else this.$log.warn("dati non validi non invio nulla");
      },
      // mostra messaggio errore e capitalizza alcuni campi
      listenForm(f) {
        document.querySelectorAll("input").forEach((i) => {
          Forms.handleErrors(i, f);
          if (
            [
              "codiceFiscale",
              "comune",
              "provincia",
              "codiceRegione",
              "codiceASL",
              "codiceSSA",
            ].includes(i.getAttribute("name"))
          ) {
            i.addEventListener("input", Forms.evTargetToUpper);
          }
          if (i.getAttribute("name") === "codiceFiscale") {
            i.addEventListener("input", this.copiaCodiceFiscaleSuCFTitolare);
          }
        });
        document
          .querySelectorAll("select")
          .forEach((s) => Forms.handleErrors(s, f));
      },
      copiaCodiceFiscaleSuCFTitolare(evt) {
        const campoCFTitolare = document.querySelector("[name=cfTitolare]");
        const cfVal = evt.target.value;
        if (
          cfVal.length === 16 &&
          R.isEmpty(document.querySelector("[name=cfTitolare]").value)
        ) {
          campoCFTitolare.value = cfVal;
          campoCFTitolare.dispatchEvent(new Event("change"));
        }
      },
      // mostra/nasconde form e imposta focus
      setUserEditable(isEdit) {
        this.toggleForm(isEdit);
        // se true focus sul primo input
        if (isEdit) {
          document
            .querySelectorAll("input:not([type=hidden]):not([readonly])")[0]
            .focus();
        }
      },
      // gestisce grafica mostra/nascondi
      toggleForm(enable) {
        let disabled = true;
        if (!R.isNil(enable)) {
          disabled = !enable;
        }
        Forms.toggleUserFormEditable(this.parentClassName, disabled);
        if (enable) {
          if (!R.isNil(this.utente.defaultDocumento.tipoCassaPrevidenziale)) {
            const el = this.$refs.formDefaultDoc.$el.querySelector(
              "[name=aliquotaCassaPrevidenziale]"
            );
            el.readOnly = false;
            el.placeholder = Forms.placeholder.cassaPrevidenziale[1];
          }
          // this.disabilitaAutoComplete()
        }
      },
      // imposta a readonly alcuni campi su aggiornamento dati
      impostaReadonly() {
        Forms.impostaDefault(
          ["codiceFiscale", "codiceClienteMida4", "partitaIVA"].map((n) => {
            return [`[name=${n}]`, "readOnly", true];
          })
        );
      },
    },
    watch: {
      utente: {
        deep: true,
        // eslint-disable-next-line no-unused-vars
        handler(newU) {
          const oldU = this.utenteDiff;
          const _handle = ({ check, fn, args = { v: this, u: newU } }) => {
            if (!check) return;
            fn.call(null, args);
          };

          const applicaHandler = this.isRegistrazione || !R.isNil(oldU);

          if (applicaHandler) {
            [
              {
                check: newU.tipoSoggetto !== oldU?.tipoSoggetto,
                fn: this.handleTipoSoggettoChange,
                args: { v: this, u: newU, resetTipoSpesa: true },
              },
              {
                check: newU.regimeFiscale !== oldU?.regimeFiscale,
                fn: Forms.handleRegimeFiscaleChange,
                args: { v: this, regimeFiscale: newU.regimeFiscale },
              },
              {
                check:
                  newU.defaultDocumento.tipoCassaPrevidenziale !==
                  oldU?.defaultDocumento?.tipoCassaPrevidenziale,
                fn: Forms.handleCassaPrevidenzialeChange,
              },
            ].forEach(_handle);
          }
          this.utenteDiff = R.clone(newU);
        },
      },
    },
    notifications: {
      showSuccess: {
        title: "Dati salvati",
        message: "I dati sono stati salvati correttamente",
        type: "success",
      },
      showError: {
        title: "Errore salvataggio dati",
        message: "Si è verificato un errore nel salvataggio dei dati utente",
        type: "error",
      },
    },
  };
</script>

<style
  src="@/styles/custom/components/_common/_form_utente.scss"
  lang="scss"
></style>

